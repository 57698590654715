import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';

export interface Attribute {
    id: number;
    name: string;
    attribute_value: number[];  // IDs of the attribute values
    options?: Array<{ id: number, name: string }>;  // Options with id and name
    selectedValue?: number;  // ID of the selected value
}

@Component({
  selector: 'app-variant-filter',
  templateUrl: './product-variants-filter.component.html',
  styleUrls: ['./product-variants-filter.component.scss'],
})
export class ProductVariantsFilterComponent implements OnInit {
    attributes: Attribute[] = [];

    selectedValueIds: number[] = [];

    constructor(
        private modalCtrl: ModalController,
        private translate: TranslateService,
        private odooApi: OdooAPI,
    ) {}

    ngOnInit() {
      this.loadAttributes();
  }
  
  loadAttributes() {
      let data = {
          model: 'storex_modules.attribute',
          domain: [],
          offset: 0,
          limit: 0,
          fields: ['name', 'attribute_value'],
      };
  
      this.odooApi.loadPublicRecords(data).subscribe(attributes => {
          if (attributes && attributes.length > 0) {
              this.attributes = attributes.map(attr => ({
                  ...attr,
                  options: [],
                  selectedValue: this.selectedValueIds.includes(attr.id) ? attr.id : null // Set selected value if present in selectedValueIds
              }));
              attributes.forEach(attribute => {
                  this.loadAttributeOptions(attribute.attribute_value, attribute.id);
              });
          }
      });
  }

    loadAttributeOptions(product_ids: number[], attributeId: number) {
      if (product_ids.length) {
          let optionData = {
              model: 'storex_modules.attribute_value',
              domain: [['id', 'in', product_ids]],
              fields: ['id', 'name'],
              limit: 0,
              offset: 0,
          };
  
          this.odooApi.loadPublicRecords(optionData).subscribe(options => {
              if (options && options.length > 0) {
                  const optionDetails = options.map(option => ({
                      id: option.id,
                      name: option.name,
                      isSelected: this.selectedValueIds.includes(option.id)  // Check if should be selected
                  }));
  
                  const index = this.attributes.findIndex(attr => attr.id === attributeId);
                  if (index !== -1) {
                      this.attributes[index].options = optionDetails;
                      // Automatically select the value if it's in selectedValueIds
                      this.attributes[index].selectedValue = optionDetails.find(opt => opt.isSelected)?.id;
                  }
              }
          });
      }
  }
  

    close() {
      this.modalCtrl.dismiss({
        selectedValueIds: null,
        save: false
      });
    }
  

    saveFilterAndLeave() {
      const selectedValueIds = this.attributes
          .map(attr => attr.selectedValue)
          .filter(valueId => valueId != null); 
  
      if(selectedValueIds.length > 0) {
        this.modalCtrl.dismiss({
            selectedValueIds: selectedValueIds
        });
      }
      else 
        this.close();
  }
  
}

