import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { SettingPage } from 'src/app/pages/setting/setting.page';
import { WishlistPage } from '../../wishlist/wishlist.page';
import { SharedService } from 'src/app/services/sharedService';
import * as ProductSelector from 'src/app/stores/product/store/selectors'
import * as ProductActions from 'src/app/stores/product/store/actions'
import { ProductState } from 'src/app/stores/product/store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-modern-template-tabs',
  templateUrl: './modern-template-tabs.component.html',
  styleUrls: ['./modern-template-tabs.component.scss'],
})
export class ModernTemplateTabsComponent implements OnInit {
  activeRoute='landing-page'

  constructor(
    public translate:TranslateService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private modalCtrl: ModalController,
    public sharedService : SharedService,
    private productStore : Store<ProductState>
  ) { }

  menuItems = [
    { name: 'Home', icon: 'home', route: 'landing-page' },
    { name: 'Category', icon: 'grid', route: 'category' },
    { name: 'Search', icon: 'search', route: 'search' },
    { name: 'Settings', icon: 'settings', route: 'settings' }
  ];

  getIconPath_catogary(route: string): SafeResourceUrl {
    const iconPath = route === 'category' ? './assets/icons/grid.svg' : '../../../../assets/icon/tabs_icon_inactive.svg';
    return this.sanitizer.bypassSecurityTrustResourceUrl(iconPath);
  }

  ngOnInit() {
  }

  getMenuItemIndex(routeName: string): number {
    return this.menuItems.findIndex(item => item.route === routeName);
  }

  selectedHome(route){
    if(route=='settings'){
      const modal = this.modalCtrl.create({
        component: SettingPage,
        componentProps:{
          showBackButton:true
        }
        
      })
      modal.then(mod => mod.present())
    } else if(route=='fav'){
      const modal = this.modalCtrl.create({
        component: WishlistPage,
        componentProps:{
          showBackButton:true
        }
        
      })
      modal.then(mod => mod.present())
    } else if(route=='search'){
      this.productStore.dispatch(new ProductActions.UpdateSearch([]))
      setTimeout(()=>{
        this.activeRoute=route
        this.router.navigate([route])
      },200)
    } else {
      this.activeRoute=route
      this.router.navigate([route])
    }



  }



}

