<ion-content style="--overflow: hidden; --ion-background-color: white;">
  <ion-list>
    <ion-item style="width: 94%">
      <ion-row style="align-items: center; gap: 15px; margin: 1vh 0">
        <ion-img style="width: 25px" src="../../../assets/icon/filter_small_icon.svg"></ion-img>
        <ion-label class="dialog-title">{{ "VARIANTS_FILTER" | translate }}</ion-label>
      </ion-row>
    </ion-item>
    <ion-item class="item-center" style="width: 94%; height: 18vh; overflow: auto;">
      <ion-grid style="padding: 0; display: grid; margin: 1vh 0">
        <div *ngFor="let attribute of attributes; let i = index">
          <ion-row>
            <ion-col size="12">
              <ion-item>
                <ion-label>{{ attribute.name | translate}}</ion-label>
                <ion-select [(ngModel)]="attribute.selectedValue" placeholder="Select one">
                  <ion-select-option *ngFor="let option of attribute.options" [value]="option.id">{{ option.name | translate}}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
    </ion-item>
    <ion-item style="height: 7vh">
      <ion-grid style="padding: 0; height: 6.5vh; display: grid">
        <ion-row style="align-items: center">
          <ion-col style="display: contents">
            <ion-button mode="ios" color="dark" class="close-button" (click)="close()">
              <span style="font-size: 0.8rem; color: #333333; display: flex; gap: 10px; background-color: white; align-items: center;">
                <ion-img style="width: 25px" src="../../../assets/icon/clear_icon.svg"></ion-img>
                {{ "CLEAR" | translate }}
              </span>
            </ion-button>
          </ion-col>
          <ion-col style="display: contents">
            <ion-button mode="ios" color="dark" class="button-reports" (click)="saveFilterAndLeave()">
              <span style="font-size: 0.8rem; color: white">{{ "APPLY" | translate }}</span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
  </ion-list>
</ion-content>
