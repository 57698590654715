class Environment {

  public production: boolean;
  public websiteTitle: string;
  public db: string;
  public url: string;
  public shopUrl: string;
  public firebase: {};
  public pwaPrompt: any;
  constructor() {
    this.production = false;
    this.websiteTitle="Storex";
    this.db=window.location.hostname.split('.')[0];
    this.url="https://"+window.location.hostname.split('.')[0]+".olivery.io";
    this.shopUrl=window.location.hostname;
    // need to replace firebase with new values when use the white label for any client
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      console.log('beforeinstallprompt event captured'); // Add this to check if the event is captured
      this.pwaPrompt = e;
    });
    this.firebase= {
      apiKey: "AIzaSyA4YK13xh_mb3P7hvGikAc5HRkH-JWfhno",
      authDomain: "storex-3346b.firebaseapp.com",
      projectId: "storex-3346b",
      storageBucket: "storex-3346b.appspot.com",
      messagingSenderId: "1057235356158",
      appId: "1:1057235356158:web:5094f38bfcfb2c74e8d8af",
      measurementId: "G-68GQ6MM165"
    }
  }
}

export const environment = new Environment();