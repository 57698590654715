
import { Component, OnInit, OnDestroy } from '@angular/core';
import {  NavController, Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { startWith, debounceTime, takeUntil, skip } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { IProduct } from 'src/app/stores/product/model';
import { ProductState } from 'src/app/stores/product/store';
import * as productActions from 'src/app/stores//product/store/actions';
import * as productSelector from 'src/app/stores//product/store/selectors';
import { SharedService } from 'src/app/services/sharedService';
import { ModalController, ToastController } from '@ionic/angular';
import { ProductVariantsFilterComponent } from 'src/app/components/product-variants-filter/product-variants-filter.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.page.html',
  styleUrls: ['./search.page.scss'],
})
export class SearchPage implements OnInit ,OnDestroy{
  loadingProducts:boolean = false
  search: any;
  products: any;
  showCategories = true;
  limit: number=10;
  offset: number=0;
  showBackButton = false
  categories$:Observable<any[]>
  products$: Observable<IProduct[]>;
  search$: any;
  searchControl: FormControl;
  private destroyed$ = new Subject<void>();
  tags: any;
  shownCategories: any=[];
  categoryId:number
  searchTypes = [
    {
      id: 1,
      name: "PRODUCT_NAME",
      value: 'product_name'
  
    },
    {
    id: 2,
    name: "SKU",
    value: 'sku'
  },
  {
    id: 3,
    name: "BARCODE",
    value: 'barcode'
  }
  ]
  searchType: string = '';
  searchFor: any = 'product_name';
  searchValue: any;
  showMessage: boolean;
  records: number;
  isEmpty: boolean;
  FilterDomain: any;
  tempFilterDomain: any;
  template: any;
  loading=true;
  variantFilter: string =  '';
  constructor(
    public navCtrl: NavController,
    public http: HttpClient,
    private productStore: Store<ProductState>,
    public sharedService:SharedService,
    public platform: Platform,
    private modalCtrl: ModalController,
    ) { }
  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.unsubscribe()
  }

  initSearch() {
    this.searchControl = new FormControl();
    this.searchControl.valueChanges.pipe(startWith(null),debounceTime(500),skip(1),takeUntil(this.destroyed$)).subscribe(data=>{
      if(this.categoryId){
        this.FilterDomain = this.sharedService.productDomian.concat([['category_ids','in',[this.categoryId]]]) 
      }else{
        this.FilterDomain = this.sharedService.productDomian
      }
      if(this.variantFilter){
        this.FilterDomain = this.FilterDomain.concat([['products_variant_ids.name','ilike',this.variantFilter]])
      }
      if(data && data !="")
      {
        this.FilterDomain = this.FilterDomain.concat([['name', 'ilike', data]])      
        
        this.productStore.dispatch(new productActions.UpdateSearch(this.FilterDomain))
      }
      else
      {
        this.productStore.dispatch(new productActions.UpdateSearch(this.FilterDomain))
      }
    });
  }
  ionViewDidEnter(){
  }


  ngOnInit() {
    let domain = this.sharedService.productDomian
    if(this.categoryId){
      domain = domain.concat([['category_ids','in',[this.categoryId]]]) 
    }
    this.productStore.dispatch(new productActions.UpdateSearch(domain))
    this.initSearch()
    this.productStore.select(productSelector.selectAllData).pipe(takeUntil(this.destroyed$)).subscribe(data => {
      if(data && data.length > 0){
        this.products = data
        this.isEmpty = false
        
      }
      else{
        this.isEmpty = true
      }
      
      setTimeout(()=>{
         this.loading=false 
      },1000)
    })

  }
  
  back(){
    this.productStore.dispatch(new productActions.UpdateSearch([]))
    this.modalCtrl.dismiss()
  }

  showSearchFilterMenu() {
    this.modalCtrl.create({
      component: ProductVariantsFilterComponent,
      componentProps: {
        selectedValueIds: this.variantFilter,
        
      },
      cssClass: 'filter-dialog',
    }).then((modal) => {
      modal.present();

      modal.onDidDismiss().then((dataReturned) => {
        if (dataReturned.data && dataReturned.data.selectedValueIds) {
          this.variantFilter = dataReturned.data.selectedValueIds
          this.FilterDomain = this.sharedService.productDomian.concat([
            ['products_variant_ids.attribute_values_ids.id', 'in', this.variantFilter]
          ]);
          this.productStore.dispatch(new productActions.UpdateSearch(this.FilterDomain))
        } else if(dataReturned.data && !dataReturned.data.save) {
          this.variantFilter = ''
          this.FilterDomain = this.tempFilterDomain
          this.productStore.dispatch(new productActions.UpdateSearch(this.FilterDomain))
        }
      });
    })
  }
  

}