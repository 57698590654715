import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "../app.module";
import { DefaultBannerComponent } from "./default-banner/default-banner.component";
import { DefaultCategoryComponent } from "./default-category/default-category.component";
import { DefaultChildCategoryComponent } from "./default-child-category/default-child-category.component";
import { DefaultFilterPageTemplateComponent } from "./default-filter-page-template/default-filter-page-template.component";
import { DefaultLandingPageTemplateComponent } from "./default-landing-page-template/default-landing-page-template.component";
import { DefaultPackagePageTemplateComponent } from "./default-package-page-template/default-package-page-template.component";
import { DefaultProductsComponent } from "./default-products/default-products.component";
import { DefaultQuickOrderTemplateComponent } from "./default-quick-order-template/default-quick-order-template.component";
import { QuickOrderFormComponent } from "./quick-order-form/quick-order-form.component";
import { SuccessOrderComponent } from "./success-order/success-order.component";
import { TemplatesSelectorComponent } from "./templates-selector/templates-selector.component";
import { LoginFormComponent } from "./login-form/login-form.component";
import { LanguagePage } from "./language/language.page";
import { CategoriesPage } from "../pages/categories/categories.page";
import { SearchPage } from "../pages/search/search.page";
import { CartPage } from "../pages/cart/cart.page";
import { CartItemComponent } from "./cart-item/cart-item.component";
import { SettingPage } from "../pages/setting/setting.page";
import { OrdersPage } from "./orders/orders.page";
import { WishlistPage } from "./wishlist/wishlist.page";
import { ModernTemplateHeaderComponent } from "./modern-template/modern-template-header/modern-template-header.component";
import { ModernTemplateLandingPageComponent } from "./modern-template/modern-template-landing-page/modern-template-landing-page.component";
import { ModernTemplateTabsComponent } from "./modern-template/modern-template-tabs/modern-template-tabs.component";
import { ModernProductListComponent } from "./modern-template/modern-product-list/modern-product-list.component";
import { ModernCategorySliderComponent } from "./modern-template/modern-category-slider/modern-category-slider.component";
import { ModernTemplateCategoriesComponent } from "./modern-template/modern-template-categories/modern-template-categories.component";
import { ModernTemplateSearchComponent } from "./modern-template/modern-template-search/modern-template-search.component";
import { ModernTemplateCartComponent } from "./modern-template/modern-template-cart/modern-template-cart.component";
import { ModernTemplateLoginComponent } from "./modern-template/modern-template-login/modern-template-login.component";
import { ModernTemplateSettingComponent } from "./modern-template/modern-template-setting/modern-template-setting.component";
import { ModrenTemplateQuickOrderComponent } from "./modern-template/modren-template-quick-order/modren-template-quick-order.component";
import { ModernTemplateQuickOrderFormComponent } from "./modern-template/modern-template-quick-order-form/modern-template-quick-order-form.component";
import { ModernTemplateDefaultHomePage } from './modern-template/modern-template-default-home/modern-template-default-home.page';
import { ModernTemplateHomePage } from './modern-template/modern-template-home/modern-template-home.page';
import { ModernCategorySliderHomeComponent } from './modern-template/modern-category-slider-home/modern-category-slider-home.component';
import { AboutUsComponent } from "./about-us/about-us.component";
import { PrivacyAndPolicyComponent } from "./privacy-and-policy/privacy-and-policy.component";
import { ProductVariantsFilterComponent } from "./product-variants-filter/product-variants-filter.component";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule, 
        RouterModule,
        ReactiveFormsModule,
        IonicModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    exports: [
        TemplatesSelectorComponent,
        DefaultLandingPageTemplateComponent,
        DefaultBannerComponent,
        DefaultCategoryComponent,
        DefaultProductsComponent,
        DefaultChildCategoryComponent,
        DefaultQuickOrderTemplateComponent,
        QuickOrderFormComponent,
        DefaultFilterPageTemplateComponent,
        DefaultPackagePageTemplateComponent,
        SuccessOrderComponent,
        LoginFormComponent,
        LanguagePage,
        CategoriesPage,
        SearchPage,
        CartPage,
        CartItemComponent,
        SettingPage,
        OrdersPage,
        WishlistPage,
        ModernTemplateHeaderComponent,
        ModernTemplateLandingPageComponent,
        ModernTemplateTabsComponent,
        ModernProductListComponent,
        ModernCategorySliderComponent,
        ModernTemplateCategoriesComponent,
        ModernTemplateSearchComponent,
        ModernTemplateCartComponent,
        ModernTemplateLoginComponent,
        ModernTemplateSettingComponent,
        ModrenTemplateQuickOrderComponent,
        ModernTemplateQuickOrderFormComponent,
        AboutUsComponent,
        PrivacyAndPolicyComponent,
        ProductVariantsFilterComponent
    ],
    declarations: [
        TemplatesSelectorComponent,
        DefaultLandingPageTemplateComponent,
        DefaultBannerComponent,
        DefaultCategoryComponent,
        DefaultProductsComponent,
        DefaultChildCategoryComponent,
        DefaultQuickOrderTemplateComponent,
        QuickOrderFormComponent,
        DefaultFilterPageTemplateComponent,
        DefaultPackagePageTemplateComponent,
        SuccessOrderComponent,
        LoginFormComponent,
        LanguagePage,
        CategoriesPage,
        SearchPage,
        CartPage,
        CartItemComponent,
        SettingPage,
        OrdersPage,
        WishlistPage,
        ModernTemplateHeaderComponent,
        ModernTemplateLandingPageComponent,
        ModernTemplateTabsComponent,
        ModernProductListComponent,
        ModernCategorySliderComponent,
        ModernTemplateCategoriesComponent,
        ModernTemplateSearchComponent,
        ModernTemplateCartComponent,
        ModernTemplateLoginComponent,
        ModernTemplateSettingComponent,
        ModrenTemplateQuickOrderComponent,
        ModernTemplateQuickOrderFormComponent,
        ModernTemplateDefaultHomePage,
        ModernTemplateHomePage,
        ModernCategorySliderHomeComponent,
        AboutUsComponent,
        PrivacyAndPolicyComponent,
        ProductVariantsFilterComponent
    ],
    entryComponents: [
        TemplatesSelectorComponent,
        DefaultLandingPageTemplateComponent,
        DefaultBannerComponent,
        DefaultCategoryComponent,
        DefaultProductsComponent,
        DefaultChildCategoryComponent,
        DefaultQuickOrderTemplateComponent,
        QuickOrderFormComponent,
        DefaultFilterPageTemplateComponent,
        DefaultPackagePageTemplateComponent,
        SuccessOrderComponent,
        LoginFormComponent,
        LanguagePage,
        CategoriesPage,
        SearchPage,
        CartPage,
        CartItemComponent,
        SettingPage,
        OrdersPage,
        WishlistPage,
        ModernTemplateHeaderComponent,
        ModernTemplateLandingPageComponent,
        ModernTemplateTabsComponent,
        ModernProductListComponent,
        ModernCategorySliderComponent,
        ModernTemplateCategoriesComponent,
        ModernTemplateSearchComponent,
        ModernTemplateCartComponent,
        ModernTemplateLoginComponent,
        ModernTemplateSettingComponent,
        ModrenTemplateQuickOrderComponent,
        ModernTemplateQuickOrderFormComponent,
        AboutUsComponent,
        PrivacyAndPolicyComponent,
        ProductVariantsFilterComponent
    ]
  })
  export class sharedComponentModule {}